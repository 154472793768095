<template>
    <div>
        <searchBar :searchInput="false"></searchBar>
        <taxpayer-info perfect></taxpayer-info>
        <div class="table bgf">
            <form-table ref="formTable"></form-table>
            <div class="flex_cen mt_15">
                <el-button type="primary" :loading="sandLoading" :disabled="disabled" class="submit mr_20 fs_16" @click="ruleForms">{{errorNum>0?'效验数据':'确认提交'}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import taxpayerInfo from './components/taxpayer-info'
import formTable from './components/form-table'
import { s_setItem } from "@/common/cache"
export default {
	name: 'TaxesAdd',
	components: { taxpayerInfo, formTable },
	data() {
		return {
			sandLoading: false,
			errorNum: 1,
			list: [],
			disabled: false
		}
	},
	methods: {
		ruleForms() {
			this.$refs.formTable.ruleForms(()=>{
				let api = this.errorNum>0?'before_check':'batch_add'
				this.sandLoading = true
				let formData = this.$refs.formTable.forms.tableData
				let data = { import: formData }
				this.$axios({
					url: `/ent/v3/taxes/import/${api}`,
					method: 'POST',
					data,
					hideLoading: true,
					shake: true
				}).then(res => {
					setTimeout(() => {
						this.sandLoading = false
						if(res.code === 200) {
							this.errorNum = res.data.error_num
						}
					}, 900)
					if (res.code === 200) {
						if(api == 'before_check') {
							this.$refs.formTable.forms.tableData = res.data.list
						}
						if(api == 'batch_add' && !res.data.error_num) {
							s_setItem('tableData', this.$refs.formTable.forms.tableData)
							let amount = res.data.amount
							s_setItem('amount', amount)
							this.$router.push(`/payTaxes/check/${res.data.taxes_id}`)
						}
					}
				})
			})
		}
	}
}
</script>

<style lang='less' scoped>
.table {
    padding: 32px 40px;
}
.submit {
    width: 200px;
    line-height: 24px;
}
</style>